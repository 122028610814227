import { trimFieldValue } from 'ui/forms';

/*
	Standard Validation uses
		form.jQueryValidationForm,
		div.field HTML structure wrapping the field, label and validation message
		.validationMessage for the validation message element
		.validationInputGroup for validating input groups, such as sets of radio / checkbox
		.validationGroup is used for valdating a sub set of a form (e.g. used in webForms single <form> structure)

*/

(function ($) {

	// Form Validator Helpers
	const isAndroid = /Android/i.test(navigator.userAgent);

	function getValidationMessageElement (formElement) {

		// Legacy forms version - removable when legacy forms no longer exist
		{

			const accountValidationMessageElement = $(formElement).closest('dl').find('.accountValidation');
			if (accountValidationMessageElement.length > 0) {

				return accountValidationMessageElement;

			}

		}

		// Legacy cart forms version - removable when .errorMessage is replaced with .validationMessage
		{

			const errorMessageElement = $(formElement).closest('.field').find('.errorMessage');
			if (errorMessageElement.length > 0) {

				return errorMessageElement;

			}

		}

		// Standard version
		// For radio groups the validation message is based on the .validationInputGroup element
		let validationMessageElement = $(formElement).closest('.validationInputGroup');
		if (validationMessageElement.length < 1) {

			validationMessageElement = $(formElement).closest('.field');

		}
		return validationMessageElement.find('.validationMessage');

	}

	// Returns the form field (input, select, textarea...) or the fieldComposite for error highlighting
	function getFieldElement (formElement) {

		const compositeElement = $(formElement).closest('.fieldComposite');
		if (compositeElement.length > 0) {

			return compositeElement;

		}
		return formElement;

	}

	function validateZip ($form) {

		// if country dropdown doesn't exist or if country exists and val is US or CA
		if (
			!$('select.country', $form).length
			|| $('select.country', $form).val() === 'US'
			|| $('select.country', $form).val() === 'CA'
			|| (window.location.href.indexOf('/cart/') != -1
				&& $('select.country', $form).val() === $('select.country', $form).val())
		) {

			$('input.zipCode', $form).each(function () {

				$(this).rules('add', {
					restrictedCharacters: true,
					maxlength: 10,
					messages: {
						maxlength: 'Please enter no more than 10 characters.'
					}
				});

				// add zip code required rule for fields without optional class
				if (!$(this).hasClass('optional')) {

					$(this).rules('add', {
						required: true
					});

				}

			});

			// if country exists and val is not equal to US or CA

		} else {

			// remove zip code rules
			$('input.zipCode', $form).each(function () {

				$(this).rules('remove');

			});

		}

	}

	function maxLengthExceeded ($form) {

		$('input[maxlength]', $form).each(function () {

			let maximumChars;
			const character = maximumChars === 1 ? 'character' : 'characters';

			if (!maximumChars) {

				maximumChars = Number($(this).attr('maxlength'));

			}

			$(this).rules('add', {
				maxlength: maximumChars,
				messages: {
					maxlength: `Please enter no more than ${maximumChars} ${character}.`
				}
			});

		});

	}

	function multipleFormValidateAndSubmit (e) {

		const $group = $(e.currentTarget).closest('.validationGroup');
		let isValid = true;
		const validateCurrentItem = (i, item) => {

			if (!$(item).valid()) {

				isValid = false;

			}

		};

		$group
			.find(':input')
			.not('.causesValidation, [type="checkbox"], .noValidation, button')
			.each(validateCurrentItem);
		$group.find('.validateCheckboxAgree').find('input').each(validateCurrentItem);

		if (!isValid) {

			e.preventDefault();
			return false;

		}

		return true;

	}

	window.initFormValidation = function (initRules, formSelector, validationOptions) {

		formSelector = formSelector || 'form, form.jQueryValidationForm';
		initRules = typeof initRules === 'undefined' ? true : initRules; // default to true if not specified

		const $form = $(formSelector);

		const onSubmit = true;
		let validator;

		if ($form.length === 0) {

			return;

		}

		// only validate form if validation group doesn't exist
		if ($('.validationGroup').length) {

			// special form validation for multiple forms
			$form
				.find('input, textarea, select, option, button')
				.not(
					'.validationGroup input, .validationGroup textarea, .validationGroup select, .validationGroup option, .validationGroup button'
				)
				.each(function () {

					$(this).addClass('noValidation');

				});

			$('.validationGroup .causesValidation').each(function () {

				const $el = $(this);

				// For JavaScript Callbacks take out the WebForms onClick and run it against multipleFormValidationAndSubmit
				const handler = $el.prop('onclick');
				if (handler) {

					$el.removeProp('onclick')
						.removeAttr('onclick')
						.click((e) => {

							multipleFormValidateAndSubmit(e) && handler(e);

						});

				} else {

					$el.on('click', multipleFormValidateAndSubmit);

				}

			});

			$('.validationGroup :text, .validationGroup select').keydown((e) => {

				// If pressing enter on google auto complete, don't validate form
				if (e.which === 13 && $('.pac-item-selected').length) {

					e.preventDefault();

				}

			});

			$('.validationGroup:not(.ignoreEnterBtn) :text, .validationGroup:not(.ignoreEnterBtn) select').keydown(
				(e) => {

					if (e.which === 13) {

						multipleFormValidateAndSubmit(e);

					}

				}
			);

		}

		const defaultOptions = {
			onsubmit: onSubmit,
			// where error label is placed
			errorPlacement (error, element) {

				error.appendTo(getValidationMessageElement(element)).hide().fadeIn();

			},
			// provides css styling for input with error
			highlight (element, errorClass, validClass) {

				const $el = $(getFieldElement(element)).not('.noValidation');
				$el.addClass(errorClass).removeClass(validClass);
				this.settings.afterError.apply(this, arguments);

			},
			// removes css styling for input that is valid
			unhighlight (element) {

				$(getFieldElement(element)).addClass(this.settings.validClass).removeClass(this.settings.errorClass);
				getValidationMessageElement(element)
					.children(this.settings.errorElement)
					.addClass(this.settings.validClass);

			},
			// provides success message for valid input
			success (label) {

				this.afterSuccess.apply(this, arguments);

			},
			onfocusin (element) {

				$(getFieldElement(element)).closest('.fieldComposite').addClass('focus');

				$(getFieldElement(element)).closest('.field').addClass('focus');

			},
			onfocusout (element) {

				trimFieldValue(element);
				// fixes validation bug for fields that are populated on page load
				$(element).valid();

				$(getFieldElement(element)).closest('.fieldComposite').removeClass('focus');

				$(getFieldElement(element)).closest('.field').removeClass('focus');

			},
			// doesn't validate any hidden element
			ignore: ':hidden',
			// override default 'label' tag and avoid accessibility issue for an input with 2 associated labels
			errorElement: 'span',
			afterSuccess: $.noop,
			afterError: $.noop
		};

		//  merge passed validation options with default options
		validationOptions = $.extend(true, {}, defaultOptions, validationOptions);

		// form validation
		if ($form.length > 1) {

			validator = [];

			// An instance for each form
			$form.each(function () {

				validator.push($(this).off('.validate').removeData('validator').validate(validationOptions)); // remove previous validator (if any) before initializing a new one

			});

		} else {

			// If a single form
			validator = $form.off('.validate').removeData('validator').validate(validationOptions); // remove previous validator (if any) before initializing a new one

		}

		// This applies to each $form
		if (initRules && $form.length) {

			// form validation rules
			$('input.lettersOnly', $form).each(function () {

				$(this).rules('add', {
					lettersonly: true
				});

			});

			$('.default', $form).each(function () {

				$(this).rules('add', {
					restrictedCharacters: true
				});

			});

			$('.restrictedCharacters', $form).each(function () {

				$(this).rules('add', {
					restrictedCharacters: true
				});

			});

			$('input.firstName', $form).each(function () {

				const required = !$(this).hasClass('optional');
				$(this).rules('add', {
					required,
					maxlength: 30,
					restrictedCharacters: true,
					messages: {
						maxlength: '30 characters max.'
					}
				});

			});

			const addRequiredRule = () => {

				$('input.optionalGroup').each(function () {

					$(this).rules('add', { required: true });
					$(this).valid();

				});

			};

			const removeRequiredRule = () => {

				$('input.optionalGroup').each(function () {

					$(this).rules('remove', 'required');
					$(this).valid();

				});

			};

			const optionalGroup = [].slice.call(document.querySelectorAll('input.optionalGroup'));

			optionalGroup.forEach((el) => {

				el.addEventListener('keyup', () => {

					if (optionalGroup.some((el) => el.value.length > 0)) {

						addRequiredRule();

					} else {

						removeRequiredRule();

					}

				});

			});

			$('input.lastName', $form).each(function () {

				const required = !$(this).hasClass('optional');
				$(this).rules('add', {
					required,
					maxlength: 30,
					restrictedCharacters: true,
					messages: {
						maxlength: '30 characters max.'
					}
				});

			});

			$('input.email', $form).each(function () {

				$(this).rules('add', {
					required: true,
					email: true,
					emailRequireDomainSuffix: true,
					maxlength: 50,
					messages: {
						maxlength: 'Please enter no more than 50 characters.'
					}
				});

			});

			$('input.userNameAndEmailAddress', $form).each(function () {

				$(this).rules('add', {
					userNameAndEmailAddress: true,
					// disable automatic email validation for input type="email" which overrides userNameAndEmailAddress
					email: false
				});

			});

			$('input.emailNotRequired', $form).each(function () {

				$(this).rules('add', {
					email: true,
					emailRequireDomainSuffix: true,
					maxlength: 50,
					messages: {
						maxlength: 'Please enter no more than 50 characters.'
					}
				});

			});

			$('input.emailConfirm', $form).each(function () {

				$(this).rules('add', {
					required: true,
					equalTo: $('input.email', $form),
					restrictedCharacters: true
				});

			});

			$('input.address1', $form).each(function () {

				$(this).rules('add', {
					required: true,
					maxlength: 75,
					restrictedCharacters: true
				});

			});

			// LP-2666: Limited to Checkout Address 1
			$('input.noPOBox', $form).each(function () {

				$(this).rules('add', {
					isNotPOBox: true
				});

			});

			$('input.address2', $form).each(function () {

				$(this).rules('add', {
					maxlength: 75,
					restrictedCharacters: true
				});

			});

			$('select.country', $form).each(function () {

				$(this).rules('add', {
					valueNotEqualTo: '-1'
				});

			});

			validateZip($form);

			$('select.country', $form).change(() => {

				$('input.zipCode', $form).val('');
				validateZip($form);

			});

			$('input.city', $form).each(function () {

				$(this).rules('add', {
					required: true,
					maxlength: 30,
					restrictedCharacters: true
				});

			});

			$('select.state', $form).each(function () {

				$(this).rules('add', {
					valueNotEqualTo: '-1'
				});

			});

			$('input.intPhone', $form).each(function () {

				$(this).rules('add', {
					required: true,
					maxlength: 20,
					phoneInt: true,
					restrictedCharacters: true
				});

			});

			$('input.companyPhone', $form).each(function () {

				$(this).rules('add', {
					required: true,
					maxlength: 20,
					phoneInt: true,
					restrictedCharacters: true
				});

			});

			$('input.zipCode', $form).each(function () {

				$(this).rules('add', {
					restrictedCharacters: true
				});

			});

			$('input.intCellFax', $form).each(function () {

				$(this).rules('add', {
					maxlength: 20,
					phoneInt: true,
					restrictedCharacters: true
				});

			});

			if ($('.rating input[type=radio]', $form).length) {

				$('.rating input[type=radio]', $form).rules('add', {
					required: true,
					messages: {
						required: 'Please select a rating.'
					}
				});

			}

			if ($('.extra input[type=checkbox]', $form).length) {

				$('.extra input[type=checkbox]', $form).rules('add', {
					required: true,
					messages: {
						required: 'Please select an option above.'
					}
				});

			}

			if ($('.validateCheckboxAgree input[type=checkbox]', $form).length) {

				$('.validateCheckboxAgree  input[type=checkbox]', $form).rules('add', {
					required: true,
					messages: {
						required: 'You must agree to the terms before submission.'
					}
				});

			}

			if ($('input.password', $form).length) {

				$('input.password', $form).rules('add', {
					required: true,
					minlength: 6,
					maxlength: 128,
					containsAtLeastOneLetter: true,
					containsAtLeastOneDigit: true,
					restrictedCharacters: true
				});
				if ($('input.passwordConfirm', $form).length) {

					$('input.passwordConfirm', $form).rules('add', {
						required: true,
						equalTo: $('input.password', $form)
					});

				}

			}

			if ($('select.secretQuestion', $form).length) {

				$('select.secretQuestion', $form).rules('add', {
					valueNotEqualTo: '-1',
					messages: {
						required: 'Please make a selection'
					}
				});
				$('select.secretQuestion', $form).children('option:eq(0)').attr('value', '-1');
				if ($('input.answer', $form).length) {

					$('input.answer', $form).rules('add', {
						required: true,
						restrictedCharacters: true
					});

				}

			}

			if ($('input.cardNumber', $form).length) {

				$('input.cardNumber', $form).rules('add', {
					required: true,
					creditcard: true,
					maxlength: 16,
					restrictedCharacters: true
				});

			}

			if ($('input.creditCvv', $form).length) {

				$('input.creditCvv', $form).rules('add', {
					required: true,
					digits: true
				});

			}

			if ($('input.cardExpiration', $form).length) {

				$('input.cardExpiration', $form).rules('add', {
					required: true
				});

			}

			if ($('select.expMonth', $form).length) {

				$('select.expMonth', $form).rules('add', {
					required: true,
					messages: {
						required: 'Please make a selection'
					}
				});
				$('select.expMonth', $form).children('option[value="-1"]').attr('disabled', 'disabled');

			}

			if ($('select.expYear', $form).length) {

				$('select.expYear', $form).rules('add', {
					required: true,
					messages: {
						required: 'Please make a selection'
					}
				});
				$('select.expYear', $form).children('option[value="-1"]').attr('disabled', 'disabled');

			}

			if ($('input.nameOnCard', $form).length) {

				$('input.nameOnCard', $form).rules('add', {
					required: true,
					maxlength: 100,
					restrictedCharacters: true
				});

			}

			if ($('input.existingAccountId', $form).length) {

				$('input.existingAccountId', $form).rules('add', {
					digits: true
				});

			}

			if ($('select.companyProfession', $form).length) {

				$('select.companyProfession', $form).rules('add', {
					required: true, // since the first option is disabled, we cannot use valueNotEqualTo rule (optional check returns true), that's why required is used instead
					messages: {
						required: 'Please make a selection'
					}
				});
				$('select.companyProfession', $form).children('option[value="-1"]').attr('disabled', 'disabled');

			}

			if ($('input.rewardsNum', $form).length) {

				$('input.rewardsNum', $form).rules('add', {
					required: true,
					digits: true,
					minlength: 15,
					maxlength: 15,
					restrictedCharacters: true
				});

			}

			if ($('input.accessNum', $form).length) {

				$('input.accessNum', $form).rules('add', {
					required: true,
					digits: true,
					minlength: 5,
					maxlength: 5,
					restrictedCharacters: true
				});

			}

			if ($('input.companyProfessionOther', $form).length) {

				$('select.companyProfession', $form).change(function () {

					if ($(this).val() === 'Other') {

						$('.companyProfessionList').fadeIn(500);

					} else {

						$('.companyProfessionList').fadeOut(500);

					}

				});

				function ValidateCompanyProfessionOther () {

					if ($('select.companyProfession', $form).val() === 'Other') {

						$('input.companyProfessionOther', $form).rules('add', {
							required: true,
							maxlength: 256,
							restrictedCharacters: true
						});

					} else {

						$('input.companyProfessionOther', $form).rules('remove');

					}

				}
				ValidateCompanyProfessionOther();

				$('select.companyProfession', $form).change(() => {

					ValidateCompanyProfessionOther();

				});

			}

			if ($('input.subject', $form).length) {

				$('input.subject', $form).rules('add', {
					required: true,
					maxlength: 50,
					messages: {
						maxlength: 'Please enter no more than 50 characters.'
					}
				});

			}

			if ($('input.orderNum', $form).length) {

				$('input.orderNum', $form).rules('add', {
					maxlength: 40,
					messages: {
						maxlength: 'Please enter no more than 40 characters.'
					}
				});

			}

			if ($('input.optPhone', $form).length) {

				$('input.optPhone', $form).each(function () {

					$(this).rules('add', {
						maxlength: 20,
						phoneInt: true,
						messages: {
							phoneInt: 'Please specify a valid phone number',
							maxlength: 'Please enter no more than 20 characters.'
						}
					});

				});

			}

			if ($('textarea.comments', $form).length) {

				$('textarea.comments', $form).rules('add', {
					required: true,
					maxlength: 1022,
					messages: {
						maxlength: 'Please enter no more than 1022 characters.'
					}
				});

			}

			// Full System Available Quantity
			if ($('.systemOptionsQty', $form).length) {

				$('.systemOptionsQty', $form).each(function () {

					$(this).rules('add', {
						systemOptionsQtyAvailable: true
					});

				});

			}

			if ($('.inlineEmails', $form).length) {

				$('.inlineEmails', $form).each(function () {

					$(this).rules('add', {
						required: true,
						inlineEmails: {
							maxTotalEmailCount: 3,
							maxLengthPerEmail: 50
						}
					});

				});

			}

			if ($('.giftCardNumber', $form).length) {

				$('.giftCardNumber', $form).each(function () {

					$(this).rules('add', {
						required: true,
						digits: true
					});

				});

			}

			if ($('.pinNumber', $form).length) {

				$('.pinNumber', $form).each(function () {

					$(this).rules('add', {
						required: true,
						digits: true
					});

				});

			}

			if (isAndroid) {

				maxLengthExceeded($form);

			}

			// fix to trigger validation for keyboard-less operations (e.g. browser's Autofill/Autocomplete feature and Cut & Paste operations via context menu)
			$('input[type=text]', $form)
				.on('change', function () {

					const that = this;
					// only Google Chrome has built-in autofill feature that autofills all fields, other browsers only autofill the current field.
					// Firefox has webkitMatchesSelector() for some reason but we don't need it, so mozMatchesSelector check is added to exclude Firefox
					if (
						typeof this.mozMatchesSelector !== 'function'
						&& typeof this.webkitMatchesSelector === 'function'
						&& typeof this.webkitMatchesSelector('input:-webkit-autofill') === 'boolean'
						&& $('input:-webkit-autofill').length > 0
					) {

						// validate each autofilled input (needs delay to not mess up with autofill)
						setTimeout(() => {

							if ($('input:-webkit-autofill').length > 0) {

								$('input:-webkit-autofill').valid();

							}

						}, 0);

						// trigger autofill event on the field
						$(that).trigger('webkitAutoFill');

					}

				})
				// Cut/Paste via keyboard operations (e.g. CTRL+V and CTRL+X) or mouse operations (from context menu)
				.on('cut paste', function () {

					$(this).valid();

				});

		}

		return validator;

	};

}(jQuery));
